.datalist-ul {
    max-height: 350px;
    max-width: 100%;
    overflow-y: auto;
    position: absolute;
}

.datalist-li {
    max-width: 500px;
    cursor: pointer;
}

.datalist-li:hover {
    background-color: gainsboro;
}

.buttons {
    margin: 15px auto;
    width: 700px;
    text-align: center;
}

.nobtn-light-success,
.nobtn-light-success:hover,
.nobtn-light-success:active,
.nobtn-light-success:visited {
    color: #50cd89 !important;
    background-color: #e8fff3 !important;
    cursor: default !important;
    ;
}

.nobtn-light-danger,
.nobtn-light-danger:hover,
.nobtn-light-danger:active,
.nobtn-light-danger:visited {
    color: #f1416c !important;
    background-color: #fff5f8 !important;
    cursor: default !important;
    ;
}

.nobtn-light-warning,
.nobtn-light-warning:hover,
.nobtn-light-warning:active,
.nobtn-light-warning:visited {
    color: #ffc700 !important;
    background-color: #fff8dd !important;
    cursor: default !important;
    ;
}

.nobtn-light-info,
.nobtn-light-info:hover,
.nobtn-light-info:active,
.nobtn-light-info:visited {
    color: #7239ea !important;
    background-color: #f8f5ff !important;
    cursor: default !important;
    ;
}

.nobtn-light-primary,
.nobtn-light-primary:hover,
.nobtn-light-primary:active,
.nobtn-light-primary:visited {
    color: #3e97ff !important;
    background-color: #f1faff !important;
    cursor: default !important;
    ;
}


.text-escalar {
    @media screen and (max-width: 576px) {
        font-size: 18px;
    }

    @media screen and (min-width: 576px) {
        font-size: 1.8cqw;
    }

    @media screen and (min-width: 992px) {
        font-size: 1.2cqw;
    }
}

.rdt_TableCell {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important;
}

.rdt_TableCol {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important;
}
